<template>

  <div class="relative h-screen bg-black">
    <div class="bg-asgard-login bg-no-repeat w-full h-full bg-center flex justify-center items-center">
   
      <div class="flex justify-center items-center">
        <div class="border-2 border-primary-red rounded-xl flex items-center py-4 px-8 w-105 mt-8 bg-gray-50 bg-opacity-30 h-52 flex-col overflow-y-scroll">

          <div class="p-1 w-full text-primary-black font-bold">Select a Licensee to continue:</div>

          <div
          v-if="proxyUserLicensees.length"
            class="p-1 pl-4 py-2 w-full text-gray-600 font-semibold text-sm hover:text-primary-red cursor-pointer hover:bg-red-50 rounded-md"
            v-for="licensee in proxyUserLicensees"
            @click="selectLicensee(licensee)"
          >
            {{licensee.licenseeName}}
          </div>

          <div
           v-else
            class="p-1 pl-4 py-2 w-full text-gray-600 font-semibold text-sm hover:text-primary-red cursor-pointer hover:bg-red-50 rounded-md"
            v-for="licensee in userLicensees"
            @click="selectLicensee(licensee)"
          >
            {{licensee.licenseeName}}
          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'LicenseeSelection',

    setup () {

        const router = useRouter();
        const store = useStore();

        const userLicensees = computed(() => store.getters['auth/getUserLicensees']||[]);
        console.log('userLicensees ' + userLicensees.value);
        const proxyUser = computed(() => store.getters['auth/getUserProxyInfo']);
        let proxyUserLicensees = [];
        if (proxyUser.value) {
            proxyUserLicensees = proxyUser.value.licensees;
        }
        console.log(`proxyUser..licensees in lic...${JSON.stringify(proxyUser.value)}`);

        const selectLicensee = async (licensee) => {
            await store.dispatch('auth/setSelectedLicensee', licensee)

            await store.dispatch('assetsSearch/resetAssetsSearch', null, { root: true });
            await store.dispatch('assets/resetAssets', null, { root: true });
            await store.dispatch('assetsSearch/clearSavedSearches', null, { root: true });
            await store.dispatch('activeDownloads/clearDownloadsList', null, { root: true });
            await store.dispatch('basket/clearBasketCache', null, { root: true });
            await store.dispatch('styleGuideTree/resetSelectedNodeStack', null, { root: true });
            await store.dispatch('styleGuideTree/resetStyleGuideTree', null, { root: true });

            router.replace({name: 'PortalSelection'});
        };

        return {
            selectLicensee,
            userLicensees,
            proxyUser,
            proxyUserLicensees
        };
    }
};
</script>
